
body {
  margin: 0;
}

*{
  box-sizing: border-box;
  cursor: default;
}

a, a:visited, a:link {
  text-decoration: none;
  color: inherit;
}